


















































































































































@import "~vars";

.order-row .order-id {
  display: flex;
  align-items: center;

  &.needs-approval::after {
    content: "";
    background-color: $info;
    height: 1rem;
    width: 1rem;
    border-radius: 10px;
    margin-left: 0.5rem;
  }
}

.order-row .order-id .icon {
  margin-left: 1rem;
}

.order-row .checkbox{
  width: 100%;
  height: 100%;
  padding-left: 40%;
}
