






































































































































































































































































.order-row {
  cursor: pointer;
}

.orders-overview {
  th.is-active {
    border-bottom-color: #000;
  }
}

.arrow {
  margin-left: 0.5rem;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.orders-head th {
  padding-top: 2rem;
}

.search{
  display: flex;
  position:-webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 1;
}
